.top-header {
  background-color: $hawa-light-gray;

  .wrapper {
    align-items: center;

    display: flex;
    height: 100%;
    justify-content: flex-end;

    margin: 0 auto;
    max-width: $global-width;

    padding-left: (map-get($grid-margin-gutters, medium) / 2);
    padding-right: (map-get($grid-margin-gutters, medium) / 2);
  }
}
