$comp-output-table-heading-background-color: #ccc !default;
$comp-output-table-heading-color: #fff !default;
$comp-output-table-row-border-color: #ddd !default;

$comp-output-table-padding-bottom-medium-down: rem-calc(19) !default;

.comp-output-table {
  .table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .table-heading {
    th {
      background-color: $comp-output-table-heading-background-color;
      color: $comp-output-table-heading-color;
      padding: rem-calc(12) rem-calc(11);
    }

    @include breakpoint (medium down) {
      .title {
        width: 100%;
      }

      .article-number,
      .amount,
      .length,
      .height,
      .width {
        display: none;
      }
    }
  }

  .table-row {
    border-bottom: rem-calc(1) solid $comp-output-table-row-border-color;

    td {
      padding: rem-calc(17) rem-calc(12) rem-calc(15) rem-calc(12);
    }

    .inner-heading {
      display: none;

      @include breakpoint (medium down) {
        font-weight: bold;
        margin-right: rem-calc(5);
        display: inline-block;
      }
    }
  }

  // cell config
  .title {
    text-align: left;
    width: 61%;
  }

  .article-number {
    text-align: left;
  }

  .amount {
    width: 10%;
    text-align: center;
  }

  .length,
  .height,
  .width {
    text-align: right;
  }

  .title,
  .article-number,
  .amount,
  .length,
  .height,
  .width {
    &.header {
      font-weight: bold;
    }
  }

  // medium down
  @include breakpoint (medium down) {
    td {
      display: inline-block;
      width: 50%;

      &.title {
        display: block;
        width: 100%;

        padding: rem-calc(20) rem-calc(12) $comp-output-table-padding-bottom-medium-down rem-calc(12);
      }

      &.article-number,
      &.amount,
      &.length,
      &.height,
      &.width {
        width: 50%;
        text-align: left;
        padding: rem-calc(4) rem-calc(12) rem-calc(4) rem-calc(12);

        .table-value {
          display: inline-block;
          float: right;

          @include breakpoint(small down) {
            float: left;
          }
        }

        .inner-heading {
          float: left;
        }

        &.disabled {
          padding: 0;
          font-size: 0;
          width: 0;
        }
      }
    }

    .table--order-list,
    .table--layout-list {
      td {
        &.article-number,
        &.length {
          width: 70%;

          &.disabled {
            width: 0;
          }
        }

        &.amount {
          width: 30%;

          &.disabled {
            width: 0;
          }
        }
      }
    }

    .table--order-list,
    .table--layout-list,
    .table--glasElements,
    .table--doorWidthElements{
      td {
        vertical-align: top;

        &.article-number {
          &.header {
            padding-bottom: $comp-output-table-padding-bottom-medium-down;
          }
        }
      }
    }

    .table--order-list {
      td {
        padding-bottom: $comp-output-table-padding-bottom-medium-down;
      }
    }

    .table--layout-list {
      td {
        &.length {
          padding-bottom: $comp-output-table-padding-bottom-medium-down;
        }
      }
    }

    .table--glasElements {
      td {
        &.width {
          padding-bottom: $comp-output-table-padding-bottom-medium-down;
        }
      }
    }

    .table--doorWidthElements {
      td {
        &.width {
          padding-bottom: $comp-output-table-padding-bottom-medium-down;
        }
      }
    }
  }
}