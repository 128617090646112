$footer-background-color: #000 !default;
$footer-text-color: #fff !default;
$hawa-link-hover: #F00 !default;

.footer {
  background-color: $footer-background-color;
  height: rem-calc(106);

  .grid-x {
    align-items: center;
  }

  .full-height {
    height: rem-calc(106);
  }

  .vert-align {
    align-items: center;
  }

  .info {
    color: $footer-text-color;
    font-size: rem-calc(16);

    &.link {

      &:hover {
        color: $hawa-link-hover;
      }
    }
  }

  @include breakpoint (small) {
    .small-order-1 {
      order: 1;
    }

    .small-order-2 {
      order: 2;
    }

    .small-align-top {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  @include breakpoint (medium up) {
    .medium-up-order-1 {
      order: 1;
    }

    .medium-up-order-2 {
      order: 2;
    }

    .align-bottom {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
  }
}
