.main-header {
  background-color: $white;
  border-bottom: 1px solid $hawa-light-gray;
  min-height: rem-calc(60);
  position: relative;

  .wrapper {
    align-items: center;
    display: block;
    height: 100%;
    margin: 0 auto;
    max-width: $global-width;
    padding-left: 0;

    @include breakpoint(large) {
      padding-left: (map-get($grid-margin-gutters, medium) / 2);
      padding-right: (map-get($grid-margin-gutters, medium) / 2);
    }
  }

  .logo-container {
    float: left;
    padding-left: (map-get($grid-margin-gutters, medium) / 2);
    position: relative;
    top: 6px;

    @include breakpoint(large) {
      padding-left: 0;
      top: (map-get($grid-margin-gutters, medium) / 2);
    }
  }

  .menu-container {
    border-top: 1px solid $hawa-light-gray;
    clear: both;
    display: none;
    left: 0;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 5;

    &.is-open {
      display: block;
    }

    @include breakpoint(large) {
      border-top: 0;
      clear: none;
      display: block;
      float: right;
      top: rem-calc($mobile-header-height + 8);
      width: auto;
    }
  }

  @include breakpoint(large) {
    height: rem-calc($main-header-height);
  }

  .logo {
    display: inline-block;
    height: rem-calc(48);

    img {
      height: 100%;
      max-width: none;
      width: auto;
    }

    @include breakpoint(large) {
      height: auto;
      width: rem-calc(150);
    }
  }

  .mobile-nav-container {
    float: right;

    @include breakpoint(large) {
      float: none;
    }
  }

  .burger {
    align-items: center;
    border-left: 1px solid $hawa-light-gray;
    cursor: pointer;
    display: flex;
    height: rem-calc(60);
    justify-content: center;
    width: rem-calc(60);

    rect {
      transition: fill 0.2s ease-in-out;
    }

    &.is-open {
      rect {
        fill: $hawa-red;
      }
    }

    @include breakpoint(large) {
      display: none;
    }
  }
}
