$badge-width: rem-calc(37) !default;

$disclaim-font-size: rem-calc(14) !default;

$large-button-bp-l: 31% !default;
$large-button-bp-m: 48% !default;
$large-button-bp-s: 100% !default;

$pdf-button-bp-l: 545px !default;
$pdf-button-bp-m: 100% !default;

.comp-form-view {
  .badge {
    width: $badge-width;
    text-align: center;
  }

  .disclaimer {
    font-size: $disclaim-font-size;
    color: $gray;
  }

  .large-button {
    margin-top: rem-calc(35);
    width: $large-button-bp-l;
    margin-bottom: rem-calc(62);
  }

  .pdf-button {
    .large-button {
      width: $pdf-button-bp-l;
    }

    @include breakpoint(medium down) {
      .large-button {
        width: $pdf-button-bp-m;
      }
    }
  }

  .print-button {
    .large-button {
      width: $pdf-button-bp-l;
    }

    @include breakpoint(medium down) {
      .large-button {
        width: $pdf-button-bp-m;
      }
    }
  }

  .large-button-group {
    .large-button {
      margin-top: rem-calc(35);
      margin-bottom: 0;
    }

    margin-bottom: rem-calc(62);
  }

  @include breakpoint(large) {
    .large-button {
      width: $large-button-bp-l;
    }
  }

  @include breakpoint(medium down) {
    .large-button {
      width: $large-button-bp-m;
    }
  }

  @include breakpoint(small down) {
    .large-button {
      width: $large-button-bp-s;
    }
  }

  .combo-selection {
    margin-bottom: rem-calc(38);
  }

  .graph-display .nav-item.is-active, .graph-display .nav-item:hover {
    display: none;
  }
}