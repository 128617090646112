$menu-list-background-color: #fff !default;
$menu-list-color: #000 !default;
$menu-list-border-color: #ccc !default;
$menu-list-mobile-header-height: 30px !default;
$hawa-link-hover: #f00 !default;

$grid-margin-gutters: (
  small: 30px,
  medium: 30px
) !default;

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    background-color: $menu-list-background-color;
    border-bottom: 1px solid $hawa-light-gray;

    > a {
      align-items: center;
      color: $menu-list-color;
      display: flex;
      height: $menu-list-mobile-header-height;
      padding-left: map-get($grid-margin-gutters, small) / 2;
      padding-right: map-get($grid-margin-gutters, small) / 2;

      &:hover {
        color: $hawa-link-hover;
      }
    }
  }

  @include breakpoint(large) {
    position: relative;

    > li {
      border: 0;
      float: left;
      margin-right: map-get($grid-margin-gutters, medium) / 2;

      &:last-child {
        margin-right: 0;
      }

      > a {
        height: auto;
        padding: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
